/**
 * Created by CaoYu on 2021/4/06.
 */
import { createVNode ,render} from 'vue'
import Modal from './Modal.vue';
const _createElement =(marker,root,tag) => {
  let target = document.querySelector(root) || document.body;
  if(target.querySelectorAll(marker).length === 0){
    let el = document.createElement(tag || 'div');
    el.setAttribute(marker, '');
    target.appendChild(el);
    return el
  }
};
function _ModalManager () {
}

_ModalManager.install = app => {
  app.config.globalProperties.$modal = function (vueComponent, options={}) {

    let modalId = `modal_${Math.random().toString().substr(3,10)}`;
    const el = _createElement(modalId);
    let vmComponentOptions = {}
    let vm = createVNode(Modal, options);
    vm.appContext = app._context;
    options.content = vmComponentOptions
    render(vm,el);
    vm.el.querySelector('[sf-modal-content]').innerHTML = ''
    if (vueComponent.constructor === Object) {
      const vmComponentProxy = {...vm.component.proxy}
      delete vmComponentProxy.content
      const vmComponent = createVNode(vueComponent,Object.assign({}, options, {modal: vmComponentProxy, title: undefined, callback: undefined}))
      vmComponent.appContext = app._context;
      render(vmComponent,vm.el.querySelector('[sf-modal-content]'));
      const vmProxy = {...vmComponent.component.proxy}
      delete vmProxy.modal
      Object.assign(vmComponentOptions, {...vmProxy})
    } else {
      vm.el.querySelector('[sf-modal-content]').innerHTML = vueComponent
    }
    return new Promise(resolve => {
      resolve(vm)
    })
  }
}
export default _ModalManager;

