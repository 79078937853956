/**
 * 功能key Options, 用户缓存字段，功能key等的记录
 * */

// token
export const SF_TOKEN = "SF_TOKEN"; // token

export const SF_NAVS = "SF_NAVS"; // SF_NAVS

export const SF_FUN_KEYS = "SF_FUN_KEYS"; //功能keys

export const SF_USER_INFO = "SF_USER_INFO"; //用户信息

