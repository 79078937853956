// import { createRouter, createWebHistory, createWebHashHistory  } from 'vue-router'
const routes = [
  {
    path: '/', meta: { title: '主页', keepAlive: false }, name: 'home', component: () => import('@/views/index'), redirect: "/login", children: [
      {
        path: "/caseRegister",
        name: "caseRegister",
        component: () => import("@/views/caseEntrust/caseRegister/index.vue"),
        meta: { title: "案件登记" }
      },
      {
        path: "/caseAcceptance",
        name: "caseAcceptance",
        component: () => import("@/views/caseEntrust/caseAcceptance/index.vue"),
        meta: { title: "案件受理" }
      },
      {
        path: "/clientManagement",
        name: "clientManagement",
        component: () => import("@/views/caseEntrust/clientManagement/index.vue"),
        meta: { title: "委托方管理" }
      },
      {
        path: "/entrustPeManagement",
        name: "entrustPeManagement",
        component: () => import("@/views/caseEntrust/entrustPeManagement/index.vue"),
        meta: { title: "委托人" }
      },
      {
        path: "/sampleManagement",
        name: "sampleManagement",
        component: () => import("@/views/sampleManagement/index.vue"),
        meta: { title: "样品管理" }
      },
      {
        path: "/caseEnd",
        name: "caseEnd",
        component: () => import("@/views/caseEnd/index.vue"),
        meta: { title: "案件归案" }
      },
      {
        path: "/allCase",
        name: "allCase",
        component: () => import("@/views/allCase/index.vue"),
        meta: { title: "所有案件" }
      },
      {
        path: "/invoiciceList",
        name: "invoiciceList",
        component: () => import("@/views/invoiciceList/index.vue"),
        meta: { title: "开票列表" }
      },
      {
        path: "/processOne",
        name: "processOne",
        component: () => import("@/views/processOne/index.vue"),
        meta: { title: "鉴定一审" }
      },
      {
        path: "/sfjdwts",
        name: "sfjdwts",
        component: () => import("@/views/customTable/sfjdwts.vue"),
        meta: { title: "司法鉴定委托书（一审）" }
      },
      {
        path: "/sfjdwtslpsb",
        name: "sfjdwtslpsb",
        component: () => import("@/views/customTable/sfjdwtslpsb.vue"),
        meta: { title: "司法鉴定委托受理评审表（一审）" }
      },
      {
        path: "/sfjdajsltzs",
        name: "sfjdajsltzs",
        component: () => import("@/views/customTable/sfjdajsltzs.vue"),
        meta: { title: "案件受理通知书（一审）" }
      },
      {
        path: "/jdcljsfqd",
        name: "jdcljsfqd",
        component: () => import("@/views/customTable/jdcljsfqd.vue"),
        meta: { title: "鉴定材料接收返还清单（一审）" }
      },
      {
        path: "/wbxxhec",
        name: "wbxxhec",
        component: () => import("@/views/customTable/wbxxhec.vue"),
        meta: { title: "外部信息核查表（一审）" }
      },
      {
        path: "/sfjdfhjy",
        name: "sfjdfhjy",
        component: () => import("@/views/customTable/sfjdfhjy.vue"),
        meta: { title: "司法鉴定复核意见（复核）" }
      },
      {
        path: "/details/details",
        name: "details",
        component: () => import("@/views/details/details.vue"),
        meta: { title: "案件详情" }
      },
      {
        path: "/processTwo",
        name: "processTwo",
        component: () => import("@/views/processTwo/index.vue"),
        meta: { title: "鉴定二审" }
      },
      {
        path: "/processThree",
        name: "processThree",
        component: () => import("@/views/processThree/index.vue"),
        meta: { title: "复核意见" }
      },
      {
        path: "/processEnd",
        name: "processEnd",
        component: () => import("@/views/processEnd/index.vue"),
        meta: { title: "终止鉴定" }
      },
      {
        path: "/jdwsshb",
        name: "jdwsshb",
        component: () => import("@/views/customTable/jdwsshb.vue"),
        meta: { title: "鉴定文书审批表（复核）" }
      },
      {
        path: "/jdwssdhz",
        name: "jdwssdhz",
        component: () => import("@/views/customTable/jdwssdhz.vue"),
        meta: { title: "鉴定文书送达回证（复核）" }
      },
      {
        path: "/gysfjdhdjlb",
        name: "gysfjdhdjlb",
        component: () => import("@/views/customTable/gysfjdhdjlb.vue"),
        meta: { title: "干预司法鉴定活动记录表（复核）" }
      },
      {
        path: "/jzml",
        name: "jzml",
        component: () => import("@/views/customTable/jzml.vue"),
        meta: { title: "卷宗目录（归档）" }
      },
      {
        path: "/jdywgd",
        name: "jdywgd",
        component: () => import("@/views/customTable/jdywgd.vue"),
        meta: { title: "鉴定业务档案（封面）（归档）" }
      },
      {
        path: "/jnbkb",
        name: "jnbkb",
        component: () => import("@/views/customTable/jnbkb.vue"),
        meta: { title: "卷内备考表（归档）" }
      },
      {
        path: "/sfjdgzs",
        name: "sfjdgzs",
        component: () => import("@/views/customTable/sfjdgzs.vue"),
        meta: { title: "司法鉴定告知书" }
      },
      {
        path: "/jdscllzjl",
        name: "jdscllzjl",
        component: () => import("@/views/customTable/jdscllzjl.vue"),
        meta: { title: "鉴定材料流转记录" }
      },
      // {
      //   path: "/sfjdjys",
      //   name: "sfjdjys",
      //   component: () => import("@/views/customTable/sfjdjys.vue"),
      //   meta: { title: "司法鉴定意见书" }
      // }
    ]
  },
  { path: '/login', meta: { title: '登录' }, name: 'Login', component: () => import('@/views/Login') },
]
// const router = createRouter({
//   // history: createWebHistory(), //使用history模式，hash模式使用 createWebHashHistory() 方法
//   history: createWebHashHistory(),
//   routes
// })
// export default router
export default routes
