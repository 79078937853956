import { SF_FUN_KEYS } from "@/utils/keys";
export const unlocking = (key) => {
    const userKeys = JSON.parse(localStorage.getItem(SF_FUN_KEYS));
    if (Array.isArray(key)) {
        let operation = false;
        for (let j in key) {
            for (let i in userKeys) {
                if (key[j] === userKeys[i].FunctionKey) {
                    operation = true;
                    break;
                }
            }
        }
        return operation;
    }
    for (let k in userKeys) {
        if (userKeys[k].FunctionKey === key) {
            return true;
        }
    }
    return false;

}
