<template>
  <div class="Modal">
    <div class="sf-modal-mask" />
    <div class="sf-modal-messageBox" :style="{width: width, height: height}">
      <div class="sf-modal-header clearfix">
        <button aria-label="Close" class="sf-modal-close"  @click="close">
          <span class="sf-modal-close-x">
            <el-icon><CloseBold /></el-icon>
          </span>
        </button>
        <div class="sf-modal-title">{{title || ""}}</div>
      </div>
      <div class="sf-modal-body"  sf-modal-content>

      </div>
      <div class="sf-modal-footer" v-if="showFooter">
        <el-button v-if="showCancelButton"  @click="cancel">{{cancelButtonText || '取 消'}}</el-button>
        <el-button :loading="loading" v-if="showConfirmButton" type="primary" @click="confirm">{{confirmButtonText || '确 认'}}</el-button>
        <template v-if="showConfirmButtons && (showConfirmButtons.length > 0)">
          <el-button :loading="loading" v-for="(item, index) in ConfirmButtons" type="primary" @click="handleAction(item.type)">{{item.buttonText || '确 认'}}</el-button>
        </template>
      </div>

    </div>
  </div>
</template>
<script>
import debounce from 'lodash/debounce';
export default {
  name: "Modal",
  props: {
    content: {},
    title: String, // 标题
    callback: { // 回调函数
      type: Function
    },
    showCancelButton: {// 是否展示取消按钮
      type: Boolean,
      default: true
    },
    showConfirmButton:{// 是否展示确认按钮
      type: Boolean,
      default: true
    },
    showFooter:{// 是否展示fo0ter
      type: Boolean,
      default: true
    },
    cancelButtonText: String, // 取消按钮文案
    confirmButtonText: String, // 确认按钮文案
    width:String, // 默认宽度
    height:String, // 默认高度
    maxHeight: String, // 最大高度
    disablePopEvent:Boolean, // 是否禁用pop点击关闭时间
    showConfirmButtons: Boolean, // 是否展示确认按钮组
    ConfirmButtons: Array, // 按钮组
    showFullScreenButton: Boolean,
    contentHeight: String
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    handleAction(flag){
      if ((flag !== 'close') && (flag !== 'cancel')) {
        this.loading = true;
      }
      (debounce(()=>{
        this.loading = false;
        if(flag === 'pop' && this.disablePopEvent){
          return;
        }
        this.callback && this.callback(flag,this);
        if((this.callback === undefined) && (flag === 'confirm') || (flag === 'cancel') || (flag === 'pop') || (flag === 'close')){
          const parentNode = this.$el.parentNode
          setTimeout(()=>{
            if(!!this.$el.querySelector('.sf-modal-messageBox')) {
              this.$el.querySelector('.sf-modal-messageBox').className += ' v-modal-leave';
            }
            if(!!this.$el.querySelector('.sf-modal-mask')) {
              this.$el.querySelector('.sf-modal-mask').className += ' v-modal-leave';
            }
            if (this.$el.parentNode && this.$el.parentNode.removeChild) {
              this.$el.parentNode.removeChild(this.$el);
              document.body.removeChild(parentNode)
            }
          },200);
        }
        document.body.style.overflow = "auto";
      }, (flag === 'close' || flag === 'cancel') ? 0 : 600))();
    },
    confirm(){
      this.handleAction('confirm')
    },
    cancel(){
      this.handleAction('cancel')
    },
    close(){
      this.handleAction('close')
    },

  },
  created() {
  }
}
</script>

<style lang="less">
@import "./style/styles";
</style>