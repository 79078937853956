import { createApp } from 'vue'
import App from './App.vue'
// import routes from './router'
import routes from './router'
import store from './store'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import Modal from "@/plugins/modal"
import 'element-plus/dist/index.css'
import '@/assets/styles/main.less';
import { unlocking } from "@/utils/accessControl";
// import * as request from '@/api/user'
import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
// const app = createApp(App)
export async function mount(props) {
    let route = null;
    route = createRouter({
        history: createWebHashHistory(),
        routes: routes.map(item => {
            return {
                ...item,
                path: `${item.path}`
            }
        })
    })
    if (props) {
        route = {
            ...route,
            ...props.reactRoute
        }
    }
    route.beforeEach((to, from, next) => {
        if (to.meta.title) {
            document.title = to.meta.title
        }
        next()
    })
    route.onError((error) => {
        console.log(error,'error')
        const pattern = /Loading chunk chunk-(.*)+ failed/g;
        const isChunkLoadFailed = error.message.match(pattern);
        const targetPath = route.history.pending.fullPath;
        if (isChunkLoadFailed) {
            // location.reload();
            route.replace(targetPath);
        }
    });
    const app = createApp(App)
    app.config.globalProperties.$unlocking = unlocking
    // app.config.globalProperties.$request = request
    app.use(store).use(route).use(ElementPlus).use(Modal).mount('#app')
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component)
    }
}
mount();